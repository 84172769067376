<template>
  <div class="poolSettingsParent">
    <form name="poolSettingsForm" @submit="savePool">
      <p>{{ pool.name }}</p>
      <table>
        <!-- <tr>
          <td>
            <label>Anzeige Name: </label>
          </td>
          <td>
            <input v-model="pool.displayName" />
          </td>
        </tr> -->
        <tr v-if="
          pool.primaryKey == undefined ||
          pool.primaryKey == null ||
          pool.primaryKey == 0
        ">
          <td>
            <label>{{ $t("pool.internalName") }}: </label>
          </td>
          <td>
            <input class="input inputPoolName" v-model="pool.name" required @change="checkPoolName"
              @input="checkPoolName" />
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ $t("mail.mailAddress") }}: </label>
          </td>
          <td>
            <input type="email" class="input" id="incomingMailAddress" v-model="pool.incomingMailAddress"
              @change="checkPoolEmailAdress" required />
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ $t("pool.active") }}: </label>
          </td>
          <td>
            <input type="checkbox" v-model="pool.active" />
          </td>
        </tr>
        <tr>
          <td>
            <b>{{ $t("mail.incomingMails") }}</b>
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ $t("mail.protocol") }}: </label>
          </td>
          <td>
            <select class="input" v-model="pool.incomingProtocol" required>
              <option value="IMap">IMAP</option>
              <option value="Pop">POP3</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ $t("mail.server") }}: </label>
          </td>
          <td>
            <input class="input" v-model="pool.incomingHost" required />
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ $t("mail.port") }}: </label>
          </td>
          <td>
            <input class="input" type="number" min="1" max="999" v-model.number="pool.incomingPort" required />
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ $t("user.username") }}: </label>
          </td>
          <td>
            <input class="input" v-model="pool.incomingUsername" required />
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ $t("user.password") }}: </label>
          </td>
          <td>
            <input class="input" type="password" v-model="pool.incomingPassword" required />
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ $t("mail.securityOption") }}: </label>
          </td>
          <td>
            <select class="input" v-model="pool.incomingSecureOption" required>
              <option value="Auto">{{ $t("general.automatic") }}</option>
              <option value="SslOnConnect">SslOnConnect</option>
              <option value="StartTls">StartTls</option>
              <option value="StartTlsWhenAvailable">
                StartTlsWhenAvailable
              </option>
              <option value="None">{{ $t("general.nothing") }}</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ $t("pool.whichMailsToFetch") }}: </label>
          </td>
          <td>
            <select class="input" v-model="pool.incomingImapOption" required>
              <option value="NotSeen">{{ $t("pool.notSeen") }}</option>
              <option value="Seen">{{ $t("pool.seen") }}</option>
              <option value="All">{{ $t("general.all") }}</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ $t("pool.fetchInterval") }}: </label>
          </td>
          <td>
            <input class="input" type="number" min="1" max="999" v-model.number="pool.incomingScheduleInterval"
              required />
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ $t("pool.fetchIntervalTimeUnit") }}: </label>
          </td>
          <td>
            <select class="input" v-model="pool.incomingScheduleTimeUnit" required>
              <!-- <option value="Milliseconds">Millisekunden</option>
              <option value="Seconds">Sekunden</option> -->
              <option value="Minute">{{ $tc("general.minute", 2) }}</option>
              <option value="Hour">{{ $tc("general.hour", 2) }}</option>
              <option value="Day">{{ $tc("general.day", 2) }}</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            <label>Use OAuth: </label>
          </td>
          <td>
            <input type="checkbox" v-model="useOAuthIncoming" />
          </td>
        </tr>
        <tr v-if="useOAuthIncoming">
          <td>
            <label>OAuth: </label>
          </td>
          <td>
            <select class="input" v-model="incomingOAuth" required>
              <option v-for="(oauth, index) in oauths" v-bind:index="index" v-bind:key="oauth.primaryKey"
                :value="oauth.primaryKey">
                {{ oauth.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            <b>{{ $t("mail.outgoingMails") }}</b>
          </td>
        </tr>
        <!-- <tr>
          <td>
            <label>Protokoll: </label>
          </td>
          <td>
            <select v-model="pool.outgoingProtocol">
              <option value="Smtp">SMTP</option>
            </select>
          </td>
        </tr> -->
        <tr>
          <td>
            <label>{{ $t("mail.server") }}: </label>
          </td>
          <td>
            <input class="input" v-model="pool.outgoingHost" required />
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ $t("mail.port") }}: </label>
          </td>
          <td>
            <input class="input" type="number" min="1" max="999" v-model.number="pool.outgoingPort" required />
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ $t("mail.securityOption") }}: </label>
          </td>
          <td>
            <select class="input" v-model="pool.incomingSecureOption" required>
              <option value="Auto">{{ $t("general.automatic") }}</option>
              <option value="SslOnConnect">SslOnConnect</option>
              <option value="StartTls">StartTls</option>
              <option value="StartTlsWhenAvailable">
                StartTlsWhenAvailable
              </option>
              <option value="None">{{ $t("general.nothing") }}</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ $t("pool.useIncomingLoginData") }}: </label>
          </td>
          <td>
            <input type="checkbox" v-model="pool.outgoingUseIncomingCredentials" />
          </td>
        </tr>
        <tr v-if="!pool.outgoingUseIncomingCredentials">
          <td>
            <label>{{ $t("user.username") }}: </label>
          </td>
          <td>
            <input class="input" v-model="pool.outgoingUsername" required />
          </td>
        </tr>
        <tr v-if="!pool.outgoingUseIncomingCredentials">
          <td>
            <label>{{ $t("user.password") }}: </label>
          </td>
          <td>
            <input class="input" type="password" v-model="pool.outgoingPassword" required />
          </td>
        </tr>
        <tr>
          <td>
            <label>Use OAuth: </label>
          </td>
          <td>
            <input type="checkbox" v-model="useOAuthOutgoing" />
          </td>
        </tr>
        <tr v-if="useOAuthOutgoing">
          <td>
            <label>OAuth: </label>
          </td>
          <td>
            <select class="input" v-model="outgoingOAuth" required>
              <option v-for="(oauth, index) in oauths" v-bind:index="index" v-bind:key="oauth.primaryKey"
                :value="oauth.primaryKey">
                {{ oauth.name }}
              </option>
            </select>
          </td>
        </tr>
        <!-- <tr>
          <td>
            <label>Beschreibung: </label>
          </td>
          <td>
            <input v-model="pool.description" />
          </td>
        </tr> -->
      </table>
      <h2>{{ $tc("user.user", 2) }}:</h2>
      <table>
        <tr>
          <td>{{ $t("general.all") }}</td>
          <td>
            <input type="checkbox" v-model="selectAllUsers" />
          </td>
        </tr>
        <tr v-for="(user, index) in users" v-bind:index="index" v-bind:key="user.id">
          <td>{{ user.name }}</td>
          <td>
            <input type="checkbox" v-model="user.selected" />
          </td>
        </tr>
      </table>
      <button class="button input" type="submit">
        <font-awesome-icon icon="save" />
      </button>
    </form>
  </div>
</template>

<script>
import { LOADING_STARTED, LOADING_STOPPED } from "@/store/actions/loading"
import axios from "axios"
import userFunctions from "@/functions/user"
import oauthFunctions from "@/functions/oauth"
import utilsFunctions from "@/functions/utils"

export default {
  name: "PoolSettings",
  data() {
    return {
      users: [],
      selectAllUsers: false,
      oauths: [],
      useOAuthIncoming: false,
      incomingOAuth: Number,
      useOAuthOutgoing: false,
      outgoingOAuth: Number,
    }
  },
  props: {
    pool: Object,
  },
  methods: {
    checkPoolName() {
      var nameInputs = document.getElementsByClassName("inputPoolName")
      if (this.pool.name.trim() == "") {
        nameInputs.forEach((nameInput) => {
          nameInput.setCustomValidity(this.$t("general.fillOutThisField"))
        })
      } else {
        nameInputs.forEach((nameInput) => {
          nameInput.setCustomValidity("")
        })
      }
    },
    checkPoolEmailAdress(event) {
      utilsFunctions.checkEmailAdress(event.target, this.$t("mail.pleaseEnterAValidEmailAddress"))
    },
    savePool(e) {
      e.preventDefault()

      if (this.pool.name.trim() == "") {
        console.log("pool name is empty")
        return
      }

      if (
        this.pool.primaryKey == undefined ||
        this.pool.primaryKey == null ||
        this.pool.primaryKey == 0
      ) {
        this.createPool()
      } else {
        this.updatePool()
      }
    },
    async createPool() {
      this.$store.dispatch(LOADING_STARTED)

      await axios
        .post(process.env.VUE_APP_TICKET_SERVICE_API_URL_POOL_ADD, this.pool)
        .then(async (resp) => {
          this.pool.primaryKey = resp.data.primaryKey
          this.pool.readGroupId = resp.data.readGroupId
          this.pool.readWriteGroupId = resp.data.readWriteGroupId

          await this.saveOAuth()

          this.$emit("poolAdded", this.pool)
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally()
        })

      this.$store.dispatch(LOADING_STOPPED)

      this.updateUserAtPools()
    },
    async updatePool() {
      this.$store.dispatch(LOADING_STARTED)

      await axios
        .put(process.env.VUE_APP_TICKET_SERVICE_API_URL_POOL_SAVE, this.pool)
        .then(() => { })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally()
        })

      await this.saveOAuth()

      await this.$store.dispatch(LOADING_STOPPED)

      this.updateUserAtPools()
    },
    async saveOAuth() {
      if (this.useOAuthIncoming) {
        if (this.pool.incomingOpenAuthorization) {
          await oauthFunctions.removePoolOAuth(this.pool.primaryKey, this.pool.incomingOpenAuthorization.primaryKey, 0, this.$store)
        }
        await oauthFunctions.addPoolOAuth(this.pool.primaryKey, this.incomingOAuth, 0, this.$store)
        this.pool.incomingOpenAuthorization = {
          primaryKey: this.incomingOAuth
        }
      } else if (this.pool.incomingOpenAuthorization) {
        await oauthFunctions.removePoolOAuth(this.pool.primaryKey, this.pool.incomingOpenAuthorization.primaryKey, 0, this.$store)
        this.pool.incomingOpenAuthorization = null
      }

      if (this.useOAuthOutgoing) {
        if (this.pool.outgoingOpenAuthorization) {
          await oauthFunctions.removePoolOAuth(this.pool.primaryKey, this.pool.outgoingOpenAuthorization.primaryKey, 1, this.$store)
        }
        await oauthFunctions.addPoolOAuth(this.pool.primaryKey, this.outgoingOAuth, 1, this.$store)
        this.pool.outgoingOpenAuthorization = {
          primaryKey: this.incomingOAuth
        }
      } else if (this.pool.outgoingOpenAuthorization) {
        await oauthFunctions.removePoolOAuth(this.pool.primaryKey, this.pool.outgoingOpenAuthorization.primaryKey, 1, this.$store)
        this.pool.outgoingOpenAuthorization = null
      }
    },
    async updateUserAtPools() {
      let userIdsToAdd = []
      let userIdsToRemove = []

      for (let user of this.users) {
        if (user.selected) {
          userIdsToAdd.push(user.id)
        } else {
          userIdsToRemove.push(user.id)
        }
      }

      if (userIdsToAdd.length > 0) {
        await userFunctions.addUsersToGroups(
          userIdsToAdd,
          [this.pool.readGroupId, this.pool.readWriteGroupId],
          this.$store
        )
      }
      if (userIdsToRemove.length > 0) {
        await userFunctions.removeUsersFromGroups(
          userIdsToRemove,
          [this.pool.readGroupId, this.pool.readWriteGroupId],
          this.$store
        )
      }
    },
    async getUsers() {
      this.$store.dispatch(LOADING_STARTED)
      this.users = await userFunctions.getAppUsers(this.$store)
      this.$store.dispatch(LOADING_STOPPED)

      if (this.users == null) {
        // TODO an error occured
        return
      }

      // make selected reactive, that it updates the UI when changed
      for (let user of this.users) {
        this.$set(user, "selected", false)
      }

      // is needed
      this.loadUsersForPool()
    },
    async getOAuths() {
      this.oauths = await oauthFunctions.getOAuths(this.$store)

      if (this.oauths == null) {
        // an error occured
      }
    },
    async loadUsersForPool() {
      // when there are no users, it's not needed to load users for pool
      // it can also happen when the users where not loaded
      if (!this.users || this.users.length == 0) {
        return
      }

      let groupIds = []
      if (this.pool.readGroupId) {
        groupIds.push(this.pool.readGroupId)
      }
      if (this.pool.readWriteGroupId) {
        groupIds.push(this.pool.readWriteGroupId)
      }

      for (let user of this.users) {
        user.selected = false
      }

      if (groupIds && groupIds.length > 0) {
        this.selectAllUsers = false

        let usersIds = await userFunctions.loadUsersOfGroups(
          groupIds,
          this.$store
        )

        if (usersIds == null) {
          // TODO an error occured
          return
        }

        for (let user of this.users) {
          if (usersIds.includes(user.id)) {
            user.selected = true
          }
        }
      }
      this.updateSelectAllUsers()
    },
    updateSelectAllUsers() {
      let allSelected = true
      for (let user of this.users) {
        if (!user.selected) {
          allSelected = false
        }
      }
      if (allSelected) {
        this.selectAllUsers = true
      }
    },
    updateOAuth() {
      if (this.pool.incomingOpenAuthorization?.primaryKey) {
        this.useOAuthIncoming = true
        this.incomingOAuth = this.pool.incomingOpenAuthorization.primaryKey
      } else {
        this.useOAuthIncoming = false
        this.incomingOAuth = null
      }
      if (this.pool.outgoingOpenAuthorization?.primaryKey) {
        this.useOAuthOutgoing = true
        this.outgoingOAuth = this.pool.outgoingOpenAuthorization.primaryKey
      } else {
        this.useOAuthOutgoing = false
        this.outgoingOAuth = null
      }
    }
  },
  watch: {
    pool: function () {
      this.loadUsersForPool()
      this.updateOAuth()
    },
    selectAllUsers: function () {
      if (this.selectAllUsers) {
        for (let user of this.users) {
          user.selected = true
        }
      } else {
        for (let user of this.users) {
          user.selected = false
        }
      }
    },
  },
  beforeMount: function () {
    this.getUsers()
    this.getOAuths()
    this.$set(
      this.pool,
      "primaryKey",
      this.pool.primaryKey ? this.pool.primaryKey : undefined
    )
    this.updateOAuth()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table tr td {
  padding: 5px;
  min-width: 50px;
}

input {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5em;
  background: white;
}

select {
  width: 100%;
}

.poolSettingsParent {
  margin-bottom: 0.5em;
}
</style>