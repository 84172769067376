<template>
  <div class="poolsSettingsParent">
    <h1>{{ $tc("ticket.pool", 1) }} {{ $t("settings.settings") }}</h1>
    <!-- <p>{{ pools }}</p> -->
    <div id="poolsListAndPoolSettings">
      <div id="poolsList">
        <table>
          <tr
            v-for="(pool, index) in pools"
            v-bind:index="index"
            v-bind:key="pool.primaryKey"
          >
            <!-- <td>{{ pool.primaryKey }}</td> -->
            <td>{{ pool.name }}</td>
            <td>
              <span class="button input icon-button" @click="editPool(pool)">
                <font-awesome-icon icon="edit" />
              </span>
            </td>
          </tr>
        </table>
        <span class="button input icon-button" @click="addPool()">
          <font-awesome-icon icon="plus" />
        </span>
      </div>

      <hidden :xs="true" :sm="true" class="poolSettings">
        <PoolSettings
          v-if="currentPool != null"
          v-bind:pool="currentPool"
          @poolAdded="poolAdded"
        />
      </hidden>
    </div>
    <!-- Modal -->
    <hidden :md="true" :lg="true" :xl="true">
      <div id="poolModal" class="modal">
        <div class="modal-content">
          <span class="closeButton" @click="closeModal()">&times;</span>
          <PoolSettings
            v-if="currentPool != null"
            v-bind:pool="currentPool"
            @poolAdded="poolAdded"
          />
          <span class="button input" @click="closeModal()">{{
            $t("general.close")
          }}</span>
        </div>
      </div>
    </hidden>
  </div>
</template>

<script>
import { Hidden } from "vue-grid-responsive";
import PoolSettings from "@/components/settings/PoolSettings.vue";
import poolFunctions from "@/functions/pool";

export default {
  name: "PoolsSettings",
  components: {
    PoolSettings,
    Hidden,
  },
  data() {
    return {
      pools: [],
      currentPool: null,
    };
  },
  methods: {
    editPool(pool) {
      this.currentPool = pool;
      this.openModal();
    },
    addPool() {
      this.currentPool = new Object();
      // set default values
      this.currentPool.active = true;
      this.currentPool.incomingProtocol = "IMap";
      this.currentPool.incomingSecureOption = "Auto";
      this.currentPool.incomingImapOption = "NotSeen";
      this.currentPool.incomingScheduleInterval = 5;
      this.currentPool.incomingScheduleTimeUnit = "Minute";
      this.currentPool.outgoingSecureOption = "Auto";
      // this.currentPool.outgoingUseIncomingCredentials = true;

      // this.currentPool.name = "tickettest@warptec.com";
      // this.currentPool.incomingHost = "mail.ticktoo.net";
      // this.currentPool.incomingPort = 993;
      // this.currentPool.incomingUsername = "tickettest@warptec.com";
      // this.currentPool.incomingPassword = "tickettest#2021";
      // this.currentPool.outgoingHost = "mail.ticktoo.net";
      // this.currentPool.outgoingPort = 587;

      this.openModal();
    },
    poolAdded(newPool) {
      this.pools.push(newPool);
    },
    openModal() {
      var poolModal = document.getElementById("poolModal");
      poolModal.style.display = "block";
    },
    closeModal() {
      var poolModal = document.getElementById("poolModal");
      poolModal.style.display = "none";
    },
    async getPools() {
      this.pools = await poolFunctions.getPoolsByFilter("All", this.$store);

      if (this.pools == null) {
        // an error occured
      }
    },
  },
  beforeMount: function () {
    this.getPools();
  },
  mounted: function () {
    var poolModal = document.getElementById("poolModal");
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target == poolModal) {
        poolModal.style.display = "none";
      }
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.poolsSettingsParent {
  display: flex;
  flex-direction: column;
}

table tr td {
  padding: 5px;
  padding-right: 10px;
}
#poolsListAndPoolSettings {
  display: flex;
  overflow: hidden;
}
#poolsList {
  margin-right: 50px;
}

.poolSettings {
  flex-grow: 1;
  overflow-y: auto;
}
</style>