<template>
  <div class="settingsParent">
    <hidden :md="true" :lg="true" :xl="true">
      <select
        id="subMenuSelect"
        class="subMenuSelect"
        name="statusSelect"
        @change="changeSubmenu($event.target.value)"
      >
        <option
          v-for="(subMenu, key) in subMenus"
          v-bind:key="key"
          :value="key"
        >
          {{ subMenu }}
        </option>
      </select>
    </hidden>
    <div class="subMenuAndSettings">
      <hidden :xs="true" :sm="true" id="subMenu">
        <div class="subMenuWrapper">
          <a
            v-for="(subMenu, key) in subMenus"
            v-bind:key="key"
            v-bind:id="key"
            class="subMenuButton"
            @click="changeSubmenu(key)"
          >
            {{ subMenu }}
          </a>
        </div>
      </hidden>
      <component class="settingsChild" v-bind:is="dynamicComponent"></component>
    </div>
  </div>
</template>

<script>
import { Hidden } from "vue-grid-responsive";

import ProfileSettings from "@/components/settings/ProfileSettings.vue";
import PoolsSettings from "@/components/settings/PoolsSettings.vue";
import OAuthsSettings from "@/components/settings/OAuthsSettings.vue";
import UsersSettings from "@/components/settings/UsersSettings.vue";
import NotificationSettings from "@/components/settings/NotificationSettings.vue";

export default {
  name: "Settings",
  components: {
    Hidden,
    ProfileSettings,
    PoolsSettings,
    OAuthsSettings,
    UsersSettings,
    NotificationSettings,
  },
  data() {
    return {
      lastSubmenuKey: "lastSubmenu",
      subMenus: {},
      dynamicComponent: ``,
    };
  },
  // computed: {
  //   getUserId() {
  //     return this.$store.getters.userId;
  //   },
  // },
  methods: {
    changeSubmenu(key) {
      // console.log("changeSubmenu: with key: ", key);
      localStorage.setItem(this.lastSubmenuKey, key);
      this.dynamicComponent = key;

      var currentSubMenuElements = document.getElementsByClassName(
        "currentSubMenu"
      );
      for (var i = 0; i < currentSubMenuElements.length; i++) {
        currentSubMenuElements[i].classList.remove("currentSubMenu");
      }
      var keyElement = document.getElementById(key);
      if (keyElement) {
        keyElement.classList.add("currentSubMenu");
      } else {
        console.error();
        "changeSubmenu: can't find element with key: ", key;
      }
      let subMenuSelect = document.getElementById("subMenuSelect");
      if (subMenuSelect) {
        subMenuSelect.value = key;
      }
    },
    async checkAdmin() {
      if (this.$store.getters.isAdmin) {
        this.subMenus = {
          ProfileSettings: this.$t("settings.profile"),
          PoolsSettings: this.$tc("ticket.pool", 2),
          OAuthsSettings: "OAuth",
          UsersSettings: this.$tc("user.user", 2),
          NotificationSettings: this.$tc("notifications.notification", 2),
        };
        await this.$forceUpdate();
        var lastSubmenu = localStorage.getItem(this.lastSubmenuKey);
        if (lastSubmenu) {
          this.changeSubmenu(lastSubmenu);
        } else {
          this.changeSubmenu("PoolsSettings");
        }
      } else {
        this.subMenus = {
          ProfileSettings: this.$t("settings.profile"),
        };
        await this.$forceUpdate();
        this.changeSubmenu("ProfileSettings");
      }
    },
  },
  mounted: function () {
    this.checkAdmin();
    // this.unwatch = this.$store.watch(
    //   (state, getters) => getters.userId,
    //   (newValue, oldValue) => {
    //     console.log(`Updating from ${oldValue} to ${newValue}`);

    //     // Do whatever makes sense now
    //     this.checkAdmin();
    //   }
    // );
  },
  // beforeDestroy() {
  //   this.unwatch();
  // },
  // watch: {
  //   getUserId: {
  //     immediate: true,
  //     handler() {
  //       this.checkAdmin();
  //     },
  //   },
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.settingsParent {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.subMenuAndSettings {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.subMenuWrapper {
  margin: 0.5em;
}

.subMenuButton {
  cursor: pointer;
}

#subMenu {
  background: #fff;
  height: 100%;
  width: 15em;
  flex-shrink: 0;
  /* padding-left: 30px; */
  border-right: 1px solid #efefef;
  box-sizing: border-box;

  a {
    text-decoration: none;
    color: #286145;
    display: block;
    padding: 0.5em;
    transition: 0.1s;
    border-bottom: 1px solid #efefef;
    box-sizing: border-box;
  }

  a:last-child {
    border: 0;
  }

  a:hover {
    background: #efefef;
    // color: #E4E4E4;
  }
}

/* #subMenu a {
  text-decoration: none;
  color: #286145;
  display: block;
  padding: 0.5em;
  transition: 0.1s;
} */

#subMenu a.currentSubMenu {
  /* font-weight: bold; */
  background: #333;
  color: #e4e4e4;
  /* border-left: 3px solid #E4E4E4; */
  /* box-shadow: inset 2px 0 0 #e4e4e4; */
}

.settingsChild {
  padding-left: 1em;
  flex-grow: 1;
  overflow: auto;
}
</style>