<template>
  <select
    id="localeSelect"
    class="button input"
    name="localeSelect"
    @change="switchLocale($event.srcElement.value)"
  >
    <option
      v-for="locale in supportedLocales"
      :key="locale"
      :value="locale"
      :selected="locale == currentLocale"
    >
      {{ $t("locales." + locale) }}
    </option>
  </select>
</template>

<script>
import { Trans } from "@/plugins/Translation";
export default {
  name: "LocaleSwitcher",
  computed: {
    currentLocale() {
      return Trans.currentLocale;
    },
    supportedLocales() {
      return Trans.supportedLocales;
    },
  },
  methods: {
    switchLocale(locale) {
      // console.log("switchLocale loacle:", locale);
      if (this.$i18n.locale !== locale) {
        const to = this.$router.resolve({ params: { locale } });
        return Trans.changeLocale(locale).then(() => {
          this.$router.push(to.location);
        });
      }
    },
  },
};
</script>

<style scoped>
</style>