<template>
  <div class="usersSettingsParent">
    <h1>{{ $tc("user.user", 2) }} {{ $t("settings.settings") }}</h1>
    <!-- <p>{{ users }}</p> -->
    <div id="usersListAndUserSettings">
      <div id="usersList">
        <table>
          <tr
            v-for="(user, index) in users"
            v-bind:index="index"
            v-bind:key="user.primaryKey"
          >
            <td>{{ user.name }}</td>
            <td>
              <span class="button input icon-button" @click="editUser(user)">
                <font-awesome-icon icon="edit" />
              </span>
            </td>
          </tr>
        </table>
        <span class="button input icon-button" @click="addUser()">
          <font-awesome-icon icon="plus" />
        </span>
      </div>

      <hidden :xs="true" :sm="true" class="userSettings">
        <UserSettings
          v-if="currentUser != null"
          v-bind:user="currentUser"
          v-bind:users="users"
          @userAdded="userAdded"
        />
      </hidden>
    </div>
    <!-- Modal -->
    <hidden :md="true" :lg="true" :xl="true">
      <div id="userModal" class="modal">
        <div class="modal-content">
          <span class="closeButton" @click="closeModal()">&times;</span>
          <UserSettings
            v-if="currentUser != null"
            v-bind:user="currentUser"
            v-bind:users="users"
            @userAdded="userAdded"
          />
          <span class="button input" @click="closeModal()">Schließen</span>
        </div>
      </div>
    </hidden>
  </div>
</template>

<script>
import { Hidden } from "vue-grid-responsive";
import UserSettings from "@/components/settings/UserSettings.vue";
import userFunctions from "@/functions/user";
import Vue from 'vue';

export default {
  name: "UsersSettings",
  components: {
    UserSettings,
    Hidden,
  },
  data() {
    return {
      users: [],
      maxUsers: 21,
      currentUser: null,
    };
  },
  methods: {
    editUser(user) {
      this.currentUser = user;
      this.openModal();
    },
    addUser() {
      if (this.users.length >= this.maxUsers) {
        Vue.toast(this.$t("user.maxUsersReached"), {
          horizontalPosition: "center",
          className: ["et-alert"],
          duration: 5000,
          closeable: true,
          transition: "slide-down"
        })
        return;
      }
      this.currentUser = new Object();
      // set default values
      // this.currentUser.name = "testuser@warptec.com";

      this.openModal();
    },
    userAdded(newUser) {
      if (this.users) {
        this.users.push(newUser);
      } else {
        this.users = [newUser];
      }
    },
    openModal() {
      var userModal = document.getElementById("userModal");
      userModal.style.display = "block";
    },
    closeModal() {
      var userModal = document.getElementById("userModal");
      userModal.style.display = "none";
    },
    async getUsers() {
      this.users = await userFunctions.getAppUsers(this.$store);
      // console.log("getUsers users: ", this.users);

      if (!this.users) {
        console.error();
        ("UserSettings getUsers users are null");
      }
    },
  },
  beforeMount: function () {
    this.getUsers();
  },
  mounted: function () {
    var userModal = document.getElementById("userModal");
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target == userModal) {
        userModal.style.display = "none";
      }
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.usersSettingsParent {
  display: flex;
  flex-direction: column;
}

table tr td {
  padding: 5px;
  padding-right: 10px;
}
#usersListAndUserSettings {
  display: flex;
  overflow: hidden;
}
#usersList {
  margin-right: 50px;
  overflow-y: auto;
}

.userSettings {
  flex-grow: 1;
  overflow-y: auto;
}
</style>