<template>
  <div class="oauthSettingsParent">
    <form name="oauthSettingsForm" @submit="saveOAuth">
      <p>{{ oauth.name }}</p>
      <table>
        <tr>
          <td>
            <label>{{ $t("user.name") }}: </label>
          </td>
          <td>
            <input class="input inputOAuthName" v-model="oauth.name" required @change="checkOAuthName"
              @input="checkOAuthName" />
          </td>
        </tr>
        <tr>
          <td>
            <label>ClientId: </label>
          </td>
          <td>
            <input class="input" v-model="oauth.clientId" required />
          </td>
        </tr>
        <tr>
          <td>
            <label>ClientSecret: </label>
          </td>
          <td>
            <input class="input" v-model="oauth.clientSecret" required />
          </td>
        </tr>
        <tr>
          <td>
            <label>TenantId: </label>
          </td>
          <td>
            <input class="input" v-model="oauth.tenantId" required />
          </td>
        </tr>
      </table>
      <button class="button input" type="submit">
        <font-awesome-icon icon="save" />
      </button>
      <button v-if="oauth.primaryKey" type="button" class="input button"
        @click="openModal('realyDeleteOAuthModal', $t('oauth.realyDeleteOAuth'))">
        {{ $t("general.delete") }}
      </button>
    </form>
    <div id="realyDeleteOAuthModal" class="modal">
      <div class="modal-content">
        <span class="closeButton" @click="closeModal('realyDeleteOAuthModal')">&times;</span>
        <span>{{ modalMessage }}</span>
        <br />
        <br />
        <span class="button input" @click="deleteOAuth()">{{
        $t("general.yes")
        }}</span>
        &nbsp;
        <span class="button input" @click="closeModal('realyDeleteOAuthModal')">{{ $t("general.no") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import utilsFunctions from "@/functions/utils"
import oauthFunctions from "../../functions/oauth"

export default {
  name: "OAuthSettings",
  data() {
    return {
      selectAll: false,
      modalMessage: "",
    }
  },
  props: {
    oauth: Object,
  },
  methods: {
    checkOAuthName() {
      var nameInputs = document.getElementsByClassName("inputOAuthName")
      if (this.oauth.name.trim() == "") {
        nameInputs.forEach((nameInput) => {
          nameInput.setCustomValidity(this.$t("general.fillOutThisField"))
        })
      } else {
        nameInputs.forEach((nameInput) => {
          nameInput.setCustomValidity("")
        })
      }
    },
    checkOAuthEmailAdress(event) {
      utilsFunctions.checkEmailAdress(event.target, this.$t("mail.pleaseEnterAValidEmailAddress"))
    },
    async saveOAuth(e) {
      e.preventDefault()

      if (this.oauth.name.trim() == "") {
        console.log("oauth name is empty")
        return
      }

      if (
        this.oauth.primaryKey == undefined ||
        this.oauth.primaryKey == null ||
        this.oauth.primaryKey == 0
      ) {
        const createdOAuth = await oauthFunctions.createOAuth(this.oauth, this.$store)
        this.oauth.primaryKey = createdOAuth.primaryKey
        this.$emit("oauthAdded", this.oauth)
      } else {
        await oauthFunctions.updateOAuth(this.oauth, this.$store)
      }
    },
    async deleteOAuth() {
      await oauthFunctions.deleteOAuth(this.oauth.primaryKey, this.$store)
      this.closeModal("realyDeleteOAuthModal")
      this.$emit("oauthDeleted", this.oauth)
    },
    openModal(modalId, message) {
      if (message) {
        this.modalMessage = message
      } else {
        this.modalMessage = ""
      }
      var modal = document.getElementById(modalId)
      modal.style.display = "block"
    },
    closeModal(modalId) {
      var modal = document.getElementById(modalId)
      modal.style.display = "none"
    },
  },
  beforeMount: function () {
    this.$set(
      this.oauth,
      "primaryKey",
      this.oauth.primaryKey ? this.oauth.primaryKey : undefined
    )
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table tr td {
  padding: 5px;
  min-width: 50px;
}

input {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5em;
  background: white;
}

select {
  width: 100%;
}

.oauthSettingsParent {
  margin-bottom: 0.5em;
}
</style>