<template>
  <div class="profileSettings">
    <h1>{{ $t("settings.profile") }} {{ $t("settings.settings") }}</h1>

    <h2>{{ $tc("general.language", 2) }}</h2>
    <locale-switcher></locale-switcher>

    <!-- <h2>Daten ändern</h2>
    <form class="userDataForm" @submit="saveUserData">
      <label for="userName">E-Mail-Adresse (Benutzerkennung)*</label>
      <input
        class="input"
        type="text"
        id="userName"
        v-model="modifyUser.name"
        required
      />
      <label for="">Name*</label>
      <input
        class="input"
        type="text"
        id="userDescription"
        v-model="modifyUser.description"
        required
      />
      <button type="submit" class="input button">Speichern</button>
      <span class="error" v-if="dataErrorMessage != ''">{{
        dataErrorMessage
      }}</span>
    </form> -->

    <h2>{{ $tc("mail.signature", 2) }}</h2>
    <form class="signatureForm" @submit="saveSignature">
      <div class="signatureSelection">
        <select
          size="5"
          class="input"
          id="signatureSelect"
          style="margin-bottom: 1em"
          v-model="selectedSignature"
        >
          <option v-if="userArbitrary.signatures == null" disabled>
            - {{ $tc("mail.signature", 0) }} {{ $t("general.available") }} -
          </option>
          <option
            v-for="(signature, sigIndex) in userArbitrary.signatures"
            :key="sigIndex"
            :value="sigIndex"
            :title="htmlToText(signature.text)"
          >
            {{ signature.name }}
          </option>
        </select>
        <button type="button" class="input button" @click="addSignature">
          {{ $t("general.add") }}
        </button>
      </div>
      <div class="signatureOptions">
        <label for="signatureName">{{ $t("settings.designation") }}</label>
        <input
          class="input"
          type="text"
          id="signatureName"
          @input="$forceUpdate()"
          v-model="userArbitrary.signatures[selectedSignature].name"
        />
        <button type="button" class="input button" @click="deleteSignature">
          {{ $t("general.delete") }}
        </button>
      </div>
      <div class="signatureText">
        <editor ref="editor"></editor>
        <!-- <div
          class="signatureEditor input"
          contenteditable="true"
          @blur="updateSignatureText"
          v-html="userArbitrary.signatures[selectedSignature].text"
        ></div> -->
      </div>
      <button type="submit" class="input button signatureSubmit">
        {{ $t("general.save") }}
      </button>
      <span class="error" v-if="signatureErrorMessage != ''">{{
        signatureErrorMessage
      }}</span>
    </form>

    <h2>{{ $t("settings.changePassword") }}</h2>
    <form class="passwordResetForm" @submit="saveUserPassword">
      <label for="">{{ $t("settings.newPassword") }}*</label>
      <input class="input" type="password" v-model="newPassword" required />
      <label for="">{{ $t("settings.repeatNewPassword") }}*</label>
      <input
        class="input"
        type="password"
        v-model="confirmNewPassword"
        required
      />
      <button type="submit" class="input button">
        {{ $t("general.save") }}
      </button>
      <span class="error" v-if="passwordErrorMessage != ''">{{
        passwordErrorMessage
      }}</span>
    </form>
  </div>
</template>

<script>
import { LOADING_STARTED, LOADING_STOPPED } from "@/store/actions/loading";
import axios from "axios";
import userFunctions from "@/functions/user";
import Editor from "../Editor.vue";
import LocaleSwitcher from "./LocaleSwitcher.vue";

export default {
  name: "ProfileSettings",
  components: { Editor, LocaleSwitcher },
  data() {
    return {
      user: {},
      userArbitrary: {
        signatures: [
          {
            name: "Standard Signatur",
            text: "",
          },
        ],
        defaultSignature: 0,
      },
      dataErrorMessage: "",
      newPassword: "",
      confirmNewPassword: "",
      passwordErrorMessage: "",
      signatureErrorMessage: "",
      selectedSignature: 0,
    };
  },
  watch: {
    selectedSignature: {
      handler: function () {
        this.setSignatureTextToEditor();
      },
      immediate: false,
    },
  },
  methods: {
    setEditorHtml(htmlText) {
      this.$refs.editor.setHtmlInput(htmlText);
    },
    getEditorHtml() {
      return this.$refs.editor.getHtmlInput();
    },
    setSignatureTextToEditor() {
      if (this.userArbitrary.signatures[this.selectedSignature]) {
        this.setEditorHtml(
          this.userArbitrary.signatures[this.selectedSignature].text
        );
      }
    },
    setSignatureTextFromEditor() {
      this.userArbitrary.signatures[this.selectedSignature].text =
        this.getEditorHtml();
    },
    async saveUserData(e) {
      e.preventDefault();

      const updateUser = new Object();
      if (this.modifyUser.name != this.user.name) {
        updateUser.name = this.modifyUser.name;
      }
      if (this.modifyUser.description != this.user.description) {
        updateUser.description = this.modifyUser.description;
      }

      if (updateUser.name != null || updateUser.description != null) {
        updateUser.id = this.user.id;
      } else {
        this.dataErrorMessage = "Es wurde keine Änderung vorgenommen";
        return;
      }

      var userIds = await userFunctions.updateUsers([updateUser], this.$store);

      if (!userIds) {
        this.passwordErrorMessage = "Ein Fehler ist aufgetreten";
      } else {
        this.user.name = this.modifyUser.name;
        this.user.description = this.modifyUser.description;
      }
    },

    async saveUserPassword(e) {
      e.preventDefault();

      this.passwordErrorMessage = "";

      if (this.newPassword == "") {
        this.passwordErrorMessage = "Passwort darf nicht leer sein";
        return;
      } else if (this.newPassword != this.confirmNewPassword) {
        this.passwordErrorMessage = "Die Passwörter stimmen nicht überein";
        return;
      }

      const updateUser = new Object();
      if (this.user.id) {
        updateUser.id = this.user.id;
      }
      updateUser.password = this.newPassword;

      var userIds = await userFunctions.updateUsers([updateUser], this.$store);

      if (!userIds) {
        this.passwordErrorMessage = "Ein Fehler ist aufgetreten";
      }
    },

    async saveSignature(e) {
      e.preventDefault();

      // console.log("saveSignature this.userArbitrary: ", this.userArbitrary);

      this.setSignatureTextFromEditor();

      this.$store.dispatch(LOADING_STARTED);

      await axios
        .post(process.env.VUE_APP_USER_SERVICE_API_URL + "/user/setArbitrary", [
          { id: this.$store.getters.userId, object: this.userArbitrary },
        ])
        .then(() => {
          this.$store.dispatch(LOADING_STOPPED);
        })
        .catch((error) => {
          this.$store.dispatch(LOADING_STOPPED);
          this.signatureErrorMessage = "Ein Fehler ist aufgetreten";
          error.handleGlobally && error.handleGlobally();
          return;
        });
    },

    async getUser() {
      this.$store.dispatch(LOADING_STARTED);
      let tmpUser = await userFunctions.getUsers([this.$store.getters.userId]);
      this.$store.dispatch(LOADING_STOPPED);

      if (!tmpUser) {
        console.error("getUser: tmpUser is null");
        return;
      }

      this.user = tmpUser[0];

      let tmpUserArbitrary = await userFunctions.getUserArbitrary(this.$store, [
        this.$store.getters.userId,
      ]);

      tmpUserArbitrary = tmpUserArbitrary[0];

      if (tmpUserArbitrary && tmpUserArbitrary.object) {
        this.userArbitrary = tmpUserArbitrary.object;
      }

      if (this.userArbitrary != null) {
        // if signatures are of type string try to parse them to JSON
        if (typeof this.userArbitrary.signatures == "string") {
          let tmpSignatures = JSON.parse(this.userArbitrary.signatures);
          this.userArbitrary.signatures = tmpSignatures;
        }
      }

      // console.log("getUser this.userArbitrary: ", this.userArbitrary);
      this.setSignatureTextToEditor();
    },

    htmlToText(html) {
      html = html.replace(/<br\s*\/?>/gi, "\n");
      let span = document.createElement("span");
      span.innerHTML = html;
      return span.textContent || span.innerText;
    },

    deleteSignature() {
      this.userArbitrary.signatures.splice(this.selectedSignature, 1);
      this.selectedSignature -= 1;
      if (this.selectedSignature < 0) this.selectedSignature = 0;
      if (this.userArbitrary.signatures.length == 0) {
        (this.userArbitrary.signatures = [
          {
            name: "Standard Signatur",
            text: "",
          },
        ]),
          (this.userArbitrary.defaultSignature = 0);
      }
      // console.log("deleteSignature this.userArbitrary: ", this.userArbitrary);
      // console.log(
      //   "deleteSignature this.selectedSignature: ",
      //   this.selectedSignature
      // );
      this.$forceUpdate();
    },

    addSignature() {
      this.userArbitrary.signatures.push({
        name: "Neue Signatur " + (this.userArbitrary.signatures.length + 1),
        text: "",
      });
      this.$forceUpdate();
      this.selectedSignature = this.userArbitrary.signatures.length - 1;
    },

    updateSignatureText(e) {
      this.userArbitrary.signatures[this.selectedSignature].text =
        e.target.innerHTML;
    },
  },
  beforeMount: function () {
    this.getUser();
  },
  computed: {
    modifyUser: function () {
      return {
        id: this.user.id,
        name: this.user.name,
        description: this.user.description,
        resetPassword: this.user.resetPassword,
      };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.profileSettings {
  display: flex;
  flex-direction: column;
  padding-right: 1em;
  overflow-y: auto;
}

h2 {
  border-bottom: 1px solid #ddd;
}

form {
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;
  max-width: 20em;

  input {
    margin-bottom: 1em;
  }

  // input, button, select {
  //   max-width: 20em;
  // }

  select {
    padding: 0;

    option {
      text-overflow: ellipsis;
      overflow: hidden;
      outline: 0;
    }
  }
}

.signatureForm {
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  // grid-template-rows: 1fr 1fr;
  gap: 1em 1em;
  grid-template-areas:
    "area1 area2"
    "area3 area3"
    "area4 area4";

  .signatureSubmit {
    max-width: 20em;
    grid-area: area4;
  }
}

.signatureSelection {
  grid-area: area1;
  display: flex;
  flex-direction: column;

  select {
    height: 100%;
  }
}
.signatureOptions {
  grid-area: area2;
  display: flex;
  flex-direction: column;
}
.signatureText {
  grid-area: area3;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.signatureEditor {
  background: white;
  height: 6em;
  outline: none;
  overflow: auto;
  resize: vertical;
}
</style>