<template>
  <div class="userSettingsParent">
    <form name="userSettingsForm" @submit="saveUser" autocomplete="off">
      <!-- Fix for autofill -->
      <div style="position: absolute; opacity: 0; width: 0px; height: 0px">
        <label for="_email">email</label><input name="_email" />
        <label for="_password">password</label
        ><input name="_password" type="password" />
      </div>
      <!-- end fix for autofill -->
      <p>{{ user.name }}</p>
      <table>
        <tr>
          <td>
            <label
              >{{ $t("mail.mailAddress") }} ({{
                $t("login.userIdentification")
              }}):
            </label>
          </td>
          <td>
            <input
              class="input"
              type="email"
              v-model="newUser.name"
              @change="checkUserEmailAdress"
              autocomplete="off"
              required
            />
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ $t("user.password") }}: </label>
          </td>
          <td>
            <input
              class="input"
              type="password"
              v-model="newUser.password"
              :required="
                newUser.id == undefined || newUser.id == null || newUser.id == 0
              "
              autocomplete="off"
            />
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ $t("user.name") }}: </label>
          </td>
          <td>
            <input
              class="input"
              v-model="newUser.description"
              required
              autocomplete="off"
            />
          </td>
        </tr>
        <!-- <tr>
          <td>
            <label>E-Mail: </label>
          </td>
          <td>
            <input type="email" v-model="user.email" />
          </td>
        </tr> -->
        <tr>
          <td>
            <label>{{ $t("user.admin") }}: </label>
          </td>
          <td>
            <input type="checkbox" v-model="newUser.isAdmin" />
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ $t("user.resetPassword") }}: </label>
          </td>
          <td>
            <input type="checkbox" v-model="newUser.resetPassword" />
          </td>
        </tr>
      </table>
      <h2>{{ $tc("ticket.pool", 2) }}:</h2>
      <table>
        <tr>
          <td>{{ $t("general.all") }}</td>
          <td>
            <input type="checkbox" v-model="selectAll" />
          </td>
        </tr>
        <tr
          v-for="(pool, index) in pools"
          v-bind:index="index"
          v-bind:key="pool.primaryKey"
        >
          <td>{{ pool.name }}</td>
          <td>
            <input type="checkbox" v-model="pool.selected" />
          </td>
        </tr>
      </table>
      <button class="button input" type="submit">
        <font-awesome-icon icon="save" />
      </button>
    </form>
    <div id="userAlreadyExistsInOtherAppModal" class="modal">
      <div class="modal-content">
        <span
          class="closeButton"
          @click="closeModal('userAlreadyExistsInOtherAppModal')"
          >&times;</span
        >
        <span>{{ $t("user.userExistsAlready") }}</span>
        <br />
        <br />
        <span class="button input" @click="addUserFromOtherApp()">{{
          $t("general.yes")
        }}</span>
        &nbsp;
        <span
          class="button input"
          @click="closeModal('userAlreadyExistsInOtherAppModal')"
          >{{ $t("general.no") }}</span
        >
      </div>
    </div>
    <div id="messageModal" class="modal">
      <div class="modal-content">
        <span class="closeButton" @click="closeModal('messageModal')"
          >&times;</span
        >
        <span>{{ modalMessage }}</span>
        <br />
        <br />
        <span class="button input" @click="closeModal('messageModal')">{{
          $t("general.close")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { LOADING_STARTED, LOADING_STOPPED } from "@/store/actions/loading";
import poolFunctions from "@/functions/pool";
import userFunctions from "@/functions/user";
import utilsFunctions from "@/functions/utils";

export default {
  name: "UserSettings",
  data() {
    return {
      pools: [],
      selectAll: false,
      newUser: {},
      modalMessage: "",
    };
  },
  props: {
    user: Object,
    users: Array,
  },
  methods: {
    checkUserEmailAdress(event) {
      utilsFunctions.checkEmailAdress(
        event.target,
        this.$t("mail.pleaseEnterAValidEmailAddress")
      );
    },
    saveUser(e) {
      e.preventDefault();

      if (this.newUser.name.trim() == "") {
        console.log("user name is empty");
        return;
      }

      this.newUser.name = this.newUser.name.toLowerCase();

      if (
        this.newUser.id == undefined ||
        this.newUser.id == null ||
        this.newUser.id == 0
      ) {
        this.createUser();
      } else {
        this.updateUser();
      }
    },
    async createUser() {
      try {
        await userFunctions.createAppUsers([this.newUser], this.$store);
      } catch (error) {
        console.error("createUser error:", error, error.response);
        var isNameNotUniqueErr = false;
        if (error && error.response && Array.isArray(error.response.data)) {
          for (const err of error.response.data) {
            if (err.name && err.name == "eNameNotUnique") {
              isNameNotUniqueErr = true;
              break;
            }
          }
        }

        if (isNameNotUniqueErr) {
          var foundUser = this.users.find(
            (user) => user.name == this.newUser.name
          );

          if (foundUser) {
            this.openModal(
              "messageModal",
              "Ein Nutzer mit dieser E-Mail Adresse exisitiert bereits."
            );
          } else {
            this.openModal("userAlreadyExistsInOtherAppModal");
          }
        } else {
          this.openModal("messageModal", "Fehler beim Erstellen des Nutzers.");
        }
        return;
      }

      if (
        this.newUser.id == undefined ||
        this.newUser.id == null ||
        this.newUser.id == 0
      ) {
        console.error("new user was not created");
        return;
      }

      this.$emit("userAdded", this.newUser);
      this.setAddionalUserThings();
    },
    async updateUser() {
      this.$store.dispatch(LOADING_STARTED);

      const updateUser = new Object();
      if (this.newUser.id) {
        updateUser.id = this.newUser.id;
      }
      if (this.newUser.name != this.user.name) {
        updateUser.name = this.newUser.name;
      }
      if (this.newUser.password) {
        updateUser.password = this.newUser.password;
      }
      if (this.newUser.description != this.user.description) {
        updateUser.description = this.newUser.description;
      }
      if (this.newUser.resetPassword != this.user.resetPassword) {
        updateUser.resetPassword = this.newUser.resetPassword;
      }

      var userIds = await userFunctions.updateUsers([updateUser], this.$store);

      if (!userIds) {
        console.error("error while user was not updated");
        return;
      }
      this.setAddionalUserThings();
    },
    setAddionalUserThings() {
      this.copyNewUserToUser();
      this.updateUserAdminStatus(this.newUser);
      this.updateUserAtPools();
    },
    async addUserFromOtherApp() {
      this.closeModal("userAlreadyExistsInOtherAppModal");

      this.$store.dispatch(LOADING_STARTED);
      var allUsers = await userFunctions.getUsers();
      this.$store.dispatch(LOADING_STOPPED);
      var foundUser = allUsers.find((user) => user.name == this.newUser.name);

      if (foundUser) {
        await userFunctions.addUsersToAppGroup([foundUser.id], this.$store);
        this.newUser = foundUser;
        this.openModal(
          "messageModal",
          "Der Nutzer wurde zu dieser Anwendung hinzugefügt."
        );
      } else {
        console.error("user not found to add to this app");
        this.openModal("messageModal", "Fehler beim Erstellen des Nutzers.");
        return;
      }

      this.$emit("userAdded", this.newUser);
      this.setAddionalUserThings();
    },
    copyNewUserToUser() {
      this.user.id = this.newUser.id;
      this.user.name = this.newUser.name;
      // this.user.password = this.newUser.password;
      this.user.description = this.newUser.description;
      this.user.resetPassword = this.newUser.resetPassword;
    },
    async updateUserAdminStatus(user) {
      if (user.isAdmin) {
        await userFunctions.addUserToAdminGroup(user.id, this.$store);
      } else {
        await userFunctions.removeUserFromAdminGroup(user.id, this.$store);
      }
    },
    async checkIfUserIsAdmin() {
      if (this.user.id == undefined || this.user.id == null) {
        return;
      }
      var isAdmin = await userFunctions.isMemberOfAdminGroup(
        this.user.id,
        this.$store
      );
      // console.log(
      //   "checkIfUserIsAdmin userId: ",
      //   this.user.id,
      //   " isAdmin: ",
      //   isAdmin
      // );
      this.newUser.isAdmin = isAdmin;
      this.$forceUpdate();
    },
    async updateUserAtPools() {
      // console.log("updateUserAtPools pools: ", this.pools);
      let poolIdsToAdd = [];
      let poolIdsToRemove = [];

      if (this.pools == undefined || this.pools == null) {
        return;
      }

      for (let pool of this.pools) {
        if (pool.selected) {
          poolIdsToAdd.push(pool.readGroupId);
          poolIdsToAdd.push(pool.readWriteGroupId);
        } else {
          poolIdsToRemove.push(pool.readGroupId);
          poolIdsToRemove.push(pool.readWriteGroupId);
        }
      }

      if (poolIdsToAdd.length > 0) {
        await userFunctions.addUsersToGroups(
          [this.user.id],
          poolIdsToAdd,
          this.$store
        );
      }
      if (poolIdsToRemove.length > 0) {
        await userFunctions.removeUsersFromGroups(
          [this.user.id],
          poolIdsToRemove,
          this.$store
        );
      }
      this.updateSelectAll();
    },
    async getPools() {
      this.pools = await poolFunctions.getPoolsByFilter("All", this.$store);

      if (this.pools == null) {
        // TODO an error occured
        return;
      }

      // make selected reactive, that it updates the UI when changed
      for (let pool of this.pools) {
        this.$set(pool, "selected", false);
      }

      // don't seems to be needed
      // this.loadGroupsForUser(this.user.id);
    },
    async loadGroupsForUser(userId) {
      // console.log("loadGroupsForUser pools: ", this.pools);
      // when there are no pools, try to load them and if they are none than it's not needed to load groups for user
      // it can also happen when the pools where not loaded
      if (!this.pools || this.pools.length == 0) {
        await this.getPools();
        if (!this.pools || this.pools.length == 0) {
          return;
        }
      }
      for (let pool of this.pools) {
        pool.selected = false;
      }
      this.selectAll = false;

      if (userId) {
        let groups = await userFunctions.loadGroupsOfUser(userId, this.$store);

        if (groups == null) {
          // TODO an error occured
          return;
        }

        for (let pool of this.pools) {
          if (
            groups.includes(pool.readGroupId) ||
            groups.includes(pool.readWriteGroupId)
          ) {
            pool.selected = true;
          }
        }
      }
      this.updateSelectAll();
    },
    updateSelectAll() {
      // console.log("updateSelectAll");
      let allSelected = true;
      for (let pool of this.pools) {
        if (!pool.selected) {
          allSelected = false;
        }
      }
      if (allSelected) {
        this.selectAll = true;
      }
    },
    openModal(modalId, message) {
      if (message) {
        this.modalMessage = message;
      } else {
        this.modalMessage = "";
      }
      var modal = document.getElementById(modalId);
      modal.style.display = "block";
    },
    closeModal(modalId) {
      var modal = document.getElementById(modalId);
      modal.style.display = "none";
    },
  },
  // computed: {
  //   newUser: function () {
  //     console.log("computed newUser user: ", this.user);
  //     this.checkIfUserIsAdmin();
  //     this.loadGroupsForUser(this.user.id);
  //     return {
  //       id: this.user.id,
  //       name: this.user.name,
  //       description: this.user.description,
  //       resetPassword: this.user.resetPassword,
  //     };
  //   },
  // },
  watch: {
    user: {
      immediate: true,
      handler() {
        // console.log("watch user: ", this.user);
        this.checkIfUserIsAdmin();
        this.loadGroupsForUser(this.user.id);
        this.newUser = {
          id: this.user.id,
          name: this.user.name,
          description: this.user.description,
          resetPassword: this.user.resetPassword,
        };
      },
    },
    selectAll: function () {
      // console.log("watch selectAll");
      if (this.selectAll) {
        for (let pool of this.pools) {
          pool.selected = true;
        }
      } else {
        for (let pool of this.pools) {
          pool.selected = false;
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table tr td {
  padding: 5px;
  min-width: 50px;
}

input {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5em;
  background: white;
}

select {
  width: 100%;
}

.userSettingsParent {
  margin-bottom: 0.5em;
}
</style>