import axios from 'axios'
import { LOADING_STARTED, LOADING_STOPPED } from "@/store/actions/loading"
import utils from '@/functions/utils'

var oauthFunctions = {
    async getOAuths(store) {
        store.dispatch(LOADING_STARTED)

        var oauths = null

        await axios
            .get(process.env.VUE_APP_TICKET_SERVICE_API_URL + "/api/OpenAuthorization")
            .then((resp) => {
                oauths = resp.data
            })
            .catch((error) => {
                const statusCode = error.response ? error.response.status : null
                if (statusCode !== 500) {
                    error.handleGlobally && error.handleGlobally()
                } else if (statusCode !== 401) {
                    console.error(error, error.response)
                }
            })

        // if no elements, try it again after some time, because first try after login is 401
        if (!oauths) {
            await utils.delay(500)
            await axios
                .get(process.env.VUE_APP_TICKET_SERVICE_API_URL + "/api/OpenAuthorization")
                .then((resp) => {
                    oauths = resp.data
                })
                .catch((error) => {
                    error.handleGlobally && error.handleGlobally()
                })
        }

        store.dispatch(LOADING_STOPPED)

        return oauths
    },
    async updateOAuth(oauth, store) {
        store.dispatch(LOADING_STARTED)

        await axios
            .put(
                process.env.VUE_APP_TICKET_SERVICE_API_URL + "/api/OpenAuthorization/save",
                oauth
            )
            .then(() => { })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally()
            })

        store.dispatch(LOADING_STOPPED)
    },
    async createOAuth(oauth, store) {
        store.dispatch(LOADING_STARTED)

        var createdOAuth = null

        await axios
            .post(
                process.env.VUE_APP_TICKET_SERVICE_API_URL + "/api/OpenAuthorization/add",
                oauth
            )
            .then((resp) => {
                createdOAuth = resp.data
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally()
            })

        store.dispatch(LOADING_STOPPED)

        return createdOAuth
    },
    async deleteOAuth(primaryKey, store) {
        store.dispatch(LOADING_STARTED)

        await axios
            .delete(
                process.env.VUE_APP_TICKET_SERVICE_API_URL + "/api/OpenAuthorization/" + primaryKey
            )
            .then(() => { })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally()
            })

        store.dispatch(LOADING_STOPPED)
    },
    async addPoolOAuth(poolId, oauthId, mailType, store) {
        store.dispatch(LOADING_STARTED)

        await axios
            .post(
                process.env.VUE_APP_TICKET_SERVICE_API_URL + "/api/OpenAuthorization/addpooloauth/",
                {
                    poolId,
                    oauthId,
                    mailType
                }
            )
            .then(() => { })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally()
            })

        store.dispatch(LOADING_STOPPED)
    },
    async savePoolOAuth(poolId, oauthId, mailType, store) {
        store.dispatch(LOADING_STARTED)

        await axios
            .put(
                process.env.VUE_APP_TICKET_SERVICE_API_URL + "/api/OpenAuthorization/savepooloauth/",
                {
                    poolId,
                    oauthId,
                    mailType
                }
            )
            .then(() => { })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally()
            })

        store.dispatch(LOADING_STOPPED)
    },
    async removePoolOAuth(poolId, oauthId, mailType, store) {
        store.dispatch(LOADING_STARTED)

        await axios
            .delete(
                process.env.VUE_APP_TICKET_SERVICE_API_URL + "/api/OpenAuthorization/removepooloauth/", {
                data: {
                    poolId,
                    oauthId,
                    mailType
                }
            })
            .then(() => { })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally()
            })

        store.dispatch(LOADING_STOPPED)
    }
}

export default oauthFunctions