<template>
  <div class="oauthsSettingsParent">
    <h1>OAuth {{ $t("settings.settings") }}</h1>
    <div id="oauthsListAndOAuthSettings">
      <div id="oauthsList">
        <table>
          <tr
            v-for="(oauth, index) in oauths"
            v-bind:index="index"
            v-bind:key="oauth.primaryKey"
          >
            <!-- <td>{{ oauth.primaryKey }}</td> -->
            <td>{{ oauth.name }}</td>
            <td>
              <span class="button input icon-button" @click="editOAuth(oauth)">
                <font-awesome-icon icon="edit" />
              </span>
            </td>
          </tr>
        </table>
        <span class="button input icon-button" @click="addOAuth()">
          <font-awesome-icon icon="plus" />
        </span>
      </div>

      <hidden :xs="true" :sm="true" class="oauthSettings">
        <OAuthSettings
          v-if="currentOAuth != null"
          v-bind:oauth="currentOAuth"
          @oauthAdded="oauthAdded"
          @oauthDeleted="oauthDeleted"
        />
      </hidden>
    </div>
    <!-- Modal -->
    <hidden :md="true" :lg="true" :xl="true">
      <div id="oauthModal" class="modal">
        <div class="modal-content">
          <span class="closeButton" @click="closeModal()">&times;</span>
          <OAuthSettings
            v-if="currentOAuth != null"
            v-bind:oauth="currentOAuth"
            @oauthAdded="oauthAdded"
            @oauthDeleted="oauthDeleted"
          />
          <span class="button input" @click="closeModal()">{{
            $t("general.close")
          }}</span>
        </div>
      </div>
    </hidden>
  </div>
</template>

<script>
import { Hidden } from "vue-grid-responsive";
import OAuthSettings from "@/components/settings/OAuthSettings.vue";
import oauthFunctions from "@/functions/oauth";

export default {
  name: "OAuthsSettings",
  components: {
    OAuthSettings,
    Hidden,
  },
  data() {
    return {
      oauths: [],
      currentOAuth: null,
    };
  },
  methods: {
    editOAuth(oauth) {
      this.currentOAuth = oauth;
      this.openModal();
    },
    addOAuth() {
      this.currentOAuth = new Object();
      // set default values

      this.openModal();
    },
    oauthAdded(newOAuth) {
      this.oauths.push(newOAuth);
    },
    oauthDeleted(oauth) {
      const i = this.oauths.indexOf(oauth)
      this.oauths.splice(i, 1)
      this.currentOAuth = null
    },
    openModal() {
      var oauthModal = document.getElementById("oauthModal");
      oauthModal.style.display = "block";
    },
    closeModal() {
      var oauthModal = document.getElementById("oauthModal");
      oauthModal.style.display = "none";
    },
    async getOAuths() {
      this.oauths = await oauthFunctions.getOAuths(this.$store);

      if (this.oauths == null) {
        // an error occured
      }
    },
  },
  beforeMount: function () {
    this.getOAuths();
  },
  mounted: function () {
    var oauthModal = document.getElementById("oauthModal");
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target == oauthModal) {
        oauthModal.style.display = "none";
      }
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.oauthsSettingsParent {
  display: flex;
  flex-direction: column;
}

table tr td {
  padding: 5px;
  padding-right: 10px;
}
#oauthsListAndOAuthSettings {
  display: flex;
  overflow: hidden;
}
#oauthsList {
  margin-right: 50px;
}

.oauthSettings {
  flex-grow: 1;
  overflow-y: auto;
}
</style>