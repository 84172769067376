<template>
  <div class="notificationSettings">
    <h1>
      {{ $tc("notifications.notification", 2) }} {{ $t("settings.settings") }}
    </h1>

    <form
      class="notificationSettingsForm"
      @submit="saveSystemExceptionRecipient"
    >
      <h2>{{ $t("notifications.mailConnectionSettings") }}</h2>
      <p>{{ $t("notifications.mailConnectionSettingsDescription") }}</p>
      <label for="mailServer"
        >{{ $t("mail.mail") }} {{ $t("mail.server") }}</label
      >
      <input class="input" id="mailServer" v-model="mailServer" required />

      <label for="user">{{ $t("user.username") }}</label>
      <input class="input" id="user" v-model="user" required />

      <label for="password">{{ $t("user.password") }}</label>
      <input
        class="input"
        type="password"
        id="password"
        v-model="password"
        required
      />

      <label for="port">{{ $t("mail.port") }}</label>
      <input class="input" type="number" id="port" v-model="port" required />

      <h2>{{ $t("notifications.systemNotifications") }}</h2>
      <label for="systemExceptionRecipient">{{
        $t("notifications.systemNotificationsDescription")
      }}</label>
      <input
        class="input"
        type="email"
        @change="checkEmailAdress"
        id="systemExceptionRecipient"
        v-model="notifySettings.systemExceptionRecipient"
      />

      <h2>{{ $t("notifications.userNotifications") }}</h2>
      <label for="userExceptionRecipient">{{
        $t("notifications.userNotificationsDescription")
      }}</label>
      <input
        class="input"
        type="email"
        @change="checkEmailAdress"
        id="userExceptionRecipient"
        v-model="notifySettings.userExceptionRecipient"
      />

      <button type="submit" class="input button">
        {{ $t("general.save") }}
      </button>
      <span class="error" v-if="errorMessage != ''">{{ errorMessage }}</span>
    </form>
  </div>
</template>

<script>
// import { LOADING_STARTED, LOADING_STOPPED } from "@/store/actions/loading";
// import axios from "axios";
import settingsFunctions from "@/functions/settings";
import utilsFunctions from "@/functions/utils";

export default {
  name: "NotificationSettings",
  data() {
    return {
      notifySettings: {},
      mailServer: "",
      user: "",
      password: "",
      port: null,
      errorMessage: "",
    };
  },
  methods: {
    async getNotifySettings() {
      this.notifySettings = await settingsFunctions.getNotifySettings(
        this.$store
      );
      this.parseConfiguration(this.notifySettings.connection.split(";"));
    },
    checkEmailAdress(event) {
      utilsFunctions.checkEmailAdress(
        event.target,
        this.$t("mail.pleaseEnterAValidEmailAddress")
      );
    },
    async saveSystemExceptionRecipient(e) {
      e.preventDefault();

      this.notifySettings.connection =
        "Host=" +
        this.mailServer +
        ";User=" +
        this.user +
        ";Password=" +
        this.password +
        ";Port=" +
        this.port;

      try {
        await settingsFunctions.updateNotifySettings(
          this.notifySettings,
          this.$store
        );
      } catch (ex) {
        this.errorMessage = this.$t("notifications.anErrorOccurred");
        return;
      }
    },
    parseConfiguration(mailConfigs) {
      for (const mailConfig of mailConfigs) {
        let mailPropertyPair = mailConfig.split("=");
        for (let i = 0; i < mailPropertyPair.length; i += 2) {
          if (mailPropertyPair[i].toLowerCase() == "host") {
            this.mailServer = mailPropertyPair[i + 1];
          } else if (mailPropertyPair[i].toLowerCase() == "user") {
            this.user = mailPropertyPair[i + 1];
          } else if (mailPropertyPair[i].toLowerCase() == "password") {
            this.password = mailPropertyPair[i + 1];
          } else if (mailPropertyPair[i].toLowerCase() == "port") {
            this.port = parseInt(mailPropertyPair[i + 1]);
          }
        }
      }
    },
  },
  beforeMount: function () {
    this.getNotifySettings();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.notificationSettings {
  display: flex;
  flex-direction: column;
  padding-right: 1em;
  overflow-y: auto;
}

h2 {
  border-bottom: 1px solid #ddd;
}

form {
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;
  max-width: 35em;

  input {
    margin-bottom: 1em;
  }

  button[type="submit"] {
    max-width: 20em;
  }

  // input, button, select {
  //   max-width: 20em;
  // }

  select {
    padding: 0;

    option {
      text-overflow: ellipsis;
      overflow: hidden;
      outline: 0;
    }
  }
}
</style>