import axios from 'axios';
import { LOADING_STARTED, LOADING_STOPPED } from "@/store/actions/loading";
import utils from '@/functions/utils';

var settingsFunctions = {
    async getNotifySettings(store) {
        store.dispatch(LOADING_STARTED);

        var notifySettings = null;

        await axios
            .get(process.env.VUE_APP_TICKET_SERVICE_API_URL_NOTIFY_SETTINGS)
            .then((resp) => {
                notifySettings = resp.data;
            })
            .catch((error) => {
                const statusCode = error.response ? error.response.status : null;
                if (statusCode !== 500) {
                    error.handleGlobally && error.handleGlobally();
                } else if (statusCode !== 401) {
                    console.error(error, error.response);
                }
            });

        // if no notifySettings, try it again after some time, because first try after login is 401
        if (!notifySettings) {
            await utils.delay(500);
            await axios
                .get(process.env.VUE_APP_TICKET_SERVICE_API_URL_NOTIFY_SETTINGS)
                .then((resp) => {
                    notifySettings = resp.data;
                })
                .catch((error) => {
                    error.handleGlobally && error.handleGlobally();
                });
        }

        store.dispatch(LOADING_STOPPED);

        return notifySettings;
    },
    async updateNotifySettings(notifySettings, store) {
        store.dispatch(LOADING_STARTED);

        await axios
            .put(process.env.VUE_APP_TICKET_SERVICE_API_URL_NOTIFY_SETTINGS, notifySettings)
            .then(() => { })
            .catch((error) => {
                const statusCode = error.response ? error.response.status : null;
                if (statusCode !== 500) {
                    error.handleGlobally && error.handleGlobally();
                } else if (statusCode !== 401) {
                    console.error(error, error.response);
                }
            });

        store.dispatch(LOADING_STOPPED);
    }
}

export default settingsFunctions;